<script>
  import Base from '@factry/base'
  import EquipmentManager from '@factry/equipment-manager'
  import InstructionManager from '@factry/instruction-manager'
  import ShiftManager from '@factry/shift-manager'
  import MPSManager from '@factry/mps-manager'
  import DowntimeManager from '@factry/downtime-manager'
  import TeamManager from '@factry/team-manager'
  import HistorianManager from '@factry/historian-manager'
  import MessageQueueManager from '@factry/message-queue-manager'
  import MaterialManager from '@factry/material-manager'
  import OperationsManager from '@surahammars/operations-manager'
  import OrderManager from '@factry/order-manager'
  import DefectManager from '@surahammars/defect-manager'
  import WasteManager from '@factry/waste-manager'
  import GenealogyOverview from '@surahammars/genealogy-manager'
  import WarehouseManager from '@factry/warehouse-manager'
</script>

<Base
  options={[
    EquipmentManager, InstructionManager, ShiftManager, WasteManager,
    MPSManager, DowntimeManager, TeamManager, MessageQueueManager,
    MaterialManager, OperationsManager, OrderManager, HistorianManager,
    DefectManager, GenealogyOverview, WarehouseManager,
  ]}
/>
