import '@fortawesome/fontawesome-free/css/all.min.css'
import '@factry/bulma/scss/bulma.scss'
import * as Sentry from '@factry/sentry'
import { Context } from '@factry/components'
import { initialize, addLocale, setDefaultLocale, setLocale } from '@factry/components/src/translate'
import { settings as userSettings } from '@factry/user-manager'
import background from '@factry/user-manager/src/assets/background-5.jpg'
import { getCurrentUserPrivileges } from '@factry/client/privilege'
import { getTranslations } from '@factry/client/translation'
import { settings } from '@factry/client'

import App from './App.svelte'
import logo from './assets/surahammars.png'

Sentry.init({
  version: _FACTRY_VERSION,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
})

settings.basePath = import.meta.env.VITE_BASE_PATH
settings.jwt = Context.getUserKey()
addLocale('sv-SE', 'en')
setDefaultLocale('sv-SE')
const userLocale = Context.getUserLocale()
if (userLocale) setLocale(userLocale)
getTranslations().then(initialize)
if (!settings.jwt) window.location.hash = '/login'
if (settings.jwt) getCurrentUserPrivileges().then(privs => Context.setUserPrivileges(privs))

userSettings.background = background
userSettings.logo = logo
userSettings.logoAlt = 'Surahammars'

const app = new App({
  target: document.body,
})

export default app
